.about__subtitle {
  text-align: center;
  font-family: "Ride Bold", sans-serif;
  text-transform: unset;
}

.steps__title {
  text-align: center;
  font-family: "Ride Bold", sans-serif;
}

.steps__wrap {
  margin-top: 60px;
}

.steps {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}

.step {
  text-align: center;
  width: 100%;

  p {
    font-family: "Ride Regular", sans-serif;
    font-size: 18px;
    color: #262626;
    line-height: 32px;
  }
}

.step__number {
  font-size: 30px;
  font-family: "Ride Extrabold", sans-serif;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 75px;
  height: 75px;
  background: $colorStep;
  border-radius: 50%;
  color: $colorStepNumber;
}

.step__text {
  font-size: 14px;
  line-height: 24px;
  font-family: "Ride", sans-serif;
}

.step__arrow {
  margin-top: 20px;
  margin-bottom: 20px;
}

.related {
  display: flex;
  flex-direction: column;
  padding: 0 30px;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
}

.related__card {
  text-decoration: none;
  color: #000;
  padding: 0;
  margin-bottom: 50px;

  &:hover {
    .related__card__img {
      transform: scale(1.05);

      &::before {
        opacity: 1;
      }
    }
  }
}

.related__card__title {
  display: inline-flex;
  align-items: center;
  &::before {
    content: "";
    width: 20px;
    height: 3px;
    background: #000;
    margin-right: 15px;
  }
}

.related__card__wrap {
  width: 350px;
  height: 240px;
  overflow: hidden;
}

.related__card__img {
  // width: 400px;
  // height: 250px;
  width: 100%;
  height: 100%;
  position: relative;
  transition: transform 0.3s ease;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.1);
    opacity: 0;
    transition: opacity 0.3s ease;
  }
}

.related__card__img.--csr {
  background-image: url(../../assets/img/jpg/related-card-01.jpg);
  background-position: center;
  background-size: cover;
}

.related__card__img.--partnership {
  background-image: url(../../assets/img/jpg/related-card-02.jpg);
  background-position: center;
  background-size: cover;
}

.banners-little {
  .related {
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

@media screen and (min-width: $screen-sm) {
  .related {
    display: flex;
    flex-direction: row;
    padding: 0 30px;
    justify-content: center;
  }

  .related__card__wrap {
    width: 400px;
    height: 250px;
    overflow: hidden;
  }

  .related__card {
    padding: 0 10px;
    margin-bottom: 0;
    margin-bottom: 30px;
  }

  .steps {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 60px;
  }

  .step {
    text-align: center;
    width: 25%;
  }

  .step__arrow {
    margin-top: 25px;
    margin-bottom: 0;
  }
}

@media screen and (min-width: $screen-md) {
  .related__card {
    padding: 0 10px;
    margin-bottom: 0;
  }

  .related__card__wrap {
    width: 350px;
    height: 200px;
  }
}

@media screen and (min-width: $screen-lg) {
  .related__card {
    padding: 0 20px;
  }

  .related__card__wrap {
    width: 400px;
    height: 250px;
  }
}

@media screen and (min-width: $screen-xlg) {
  .related__card {
    padding: 0 30px;
  }
}
