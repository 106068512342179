.hero {
  position: relative;
  height: 845px;
  z-index: 0;
}

.hero__bcg {
  height: 100%;
  width: 100%;
  //   background: blue;
  position: absolute;
  top: 0;
  left: 0;
  background-position: center;
  background-size: cover;
}

.hero__title {
  //   padding-top: 200px;
  margin: 0;
  display: inline-flex;
  align-items: center;
  position: absolute;
  top: 220px;

  &::before {
    content: "";
    align-self: flex-start;
    height: 7px;
    width: 40px;
    margin-right: 20px;
    margin-top: 40px;
    background: $colorDecor;
  }
}

.content {
  background-color: $colorContent;
  padding: 60px 0;
  margin-top: -400px;
  z-index: 0;
  position: relative;
  min-height: 900px;
}

.content-container {
  padding: 0 40px;
}

.rent-car {
  .hero__bcg {
    background-image: url(../../assets/img/jpg/hero-rent-car.jpg);
    background-position: 50% 70%;
  }
}

.business {
  .hero__bcg {
    background-image: url(../../assets/img/jpg/hero-business.jpg);
    background-position: center top;
  }
}

.lease {
  .hero__bcg {
    background-image: url(../../assets/img/jpg/hero-lease.jpg);
    background-position: center top;
  }
}

.short-therm {
  .hero__bcg {
    background-image: url(../../assets/img/jpg/hero-short-therm.jpg);
    background-position: center top;
  }
}

.middle-therm {
  .hero__bcg {
    background-image: url(../../assets/img/jpg/hero-middle-therm.jpg);
    background-position: center top;
  }
}

.faq {
  .hero__bcg {
    background-image: url(../../assets/img/jpg/hero-faq.jpg);
    background-position: center top;
  }
}

.about {
  .hero__bcg {
    background-image: url(../../assets/img/jpg/hero-about.jpg);
    background-position: center;
  }
}

.partnership {
  .hero__bcg {
    background-image: url(../../assets/img/jpg/hero-partnership.jpg);
    background-position: center top;
  }
}

.departments {
  .hero__bcg {
    background-image: url(../../assets/img/jpg/hero-departments.jpg);
    background-position: center top;
  }
}

.news-single {
  .hero__bcg {
    background-image: url(../../assets/img/jpg/hero-news-single.jpg);
    background-position: center top;
  }
}

.csr-hero {
  height: 445px;
  .hero__bcg {
    background-image: url(../../assets/img/jpg/hero-csr.jpg);
    background-position: center top;
  }
}

.news-hero {
  height: 725px;
  .hero__bcg {
    background-image: url(../../assets/img/jpg/hero-news.jpg);
    background-position: center top;
  }

  .news-hero__wrapper {
    position: relative;
    display: flex;
    // align-items: center;
    top: 220px;
  }

  .news-hero__date {
    position: absolute;
    top: 10px;
    left: 0;
    font-size: 42.5px;

    .news-date__day {
      padding-bottom: 15px;
      &::after {
        height: 7px;
      }
    }
    .news-date__day,
    .news-date__month {
      font-family: "Ride Extrabold", sans-serif;
    }
  }

  .hero__title {
    padding-left: 80px;
    // width: 30%;
    position: static;
    display: block;
    &::before {
      display: none;
    }
  }
}

@media screen and (min-width: $screen-sm) {
  .content-container {
    padding: 0 60px;
  }

  .hero__title {
    top: 220px;

    &::before {
      margin-top: 30px;
    }
  }

  .news-hero {
    .news-hero__date {
      top: 0;
    }
  }

  .hero.csr-hero {
    height: 745px;
  }
}

@media screen and (min-width: $screen-md) {
  .content-container {
    padding: 0 80px;
  }

  .hero__title {
    top: 220px;

    &::before {
      margin-top: 40px;
    }
  }

  .news-hero {
    .news-hero__date {
      top: 5px;
    }
  }
}

@media screen and (min-width: $screen-lg) {
  .content-container {
    padding: 0 60px;
  }

  .content__wrap {
    padding: 0 20px;
  }

  .content__big-padding {
    padding: 0 80px;
  }

  .content {
    width: 1250px;
  }

  footer > .container {
    width: 1330px;
    padding: 0 30px;
  }

  .hero > .container {
    width: 1250px;
  }

  .news-list > .container {
    width: 1330px;
    padding: 0 30px;
  }

  .banners > .container {
    width: 1330px;
  }

  .banners-little > .container {
    width: 1330px;
  }

  .news-hero {
    .news-hero__date {
      top: 10px;
    }
  }
}

@media screen and (min-width: $screen-xlg) {
  .content {
    width: 1370px;
  }

  footer > .container {
    width: 1430px;
    padding: 0 30px;
  }

  .hero > .container {
    width: 1370px;
  }

  .news-list > .container {
    width: 1430px;
    padding: 0 30px;
  }

  .banners > .container {
    width: 1430px;
  }

  .banners-little > .container {
    width: 1430px;
  }
}
