.news-list__tabs {
  margin-top: -50px;
  display: flex;
  z-index: 10;
  position: relative;
}

.news-list__tab {
  padding: 0 10px;
  height: 50px;
  background-color: $colorInactiveTabBcg;
  color: $colorInactiveTab;
  font-size: 13px;
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-right: 3px;
  font-family: "Ride Bold", sans-serif;
  font-weight: normal;
  transition: color 0.3s ease;

  &:hover {
    color: #000;
  }
}

.news-list__tab.--active-tab {
  background-color: $colorActiveTabBcg;
  color: $colorActiveTab;
  border-top: 5px solid $colorBorder;
  font-weight: normal;
}

.news-list__tab--search {
  height: 50px;
  width: 50px;
  background-color: #ffd105;
  background-image: url(../../assets/img/png/icon-search-white.png);
  background-repeat: no-repeat;
  background-position: center;
}

.news-list__search-form {
  display: flex;

  input {
    width: 0;
    background-color: #ffd105;
    color: #fff;
    font-family: "Ride Bold", sans-serif;
    font-size: 18px;
    transition: width 0.5s ease;
    padding: 0;
    border: none;

    &::placeholder {
      color: #fff;
      font-family: "Ride Bold", sans-serif;
      font-size: 18px;
      opacity: 1;
    }
  }
}

.news-list__search-form {
  .search-form__open {
    width: 250px;
  }
}

.news-category__content {
  margin: 20px 0;
  // padding: 0 30px;
}

.news-list {
  .homepage__last-newses {
    padding: 0;
  }

  .homepage__main-news {
    margin-top: 0;
    flex-direction: column-reverse;
    align-items: center;

    .homepage__main-news__title {
      &::before {
        display: none;
      }
    }
    .homepage__main-news__paragraph {
      padding-left: 0;
    }

    .img__wrap__cover {
      width: 70%;
      height: auto;
      position: relative;
      z-index: 10;
    }

    .homepage__main-news__image {
      width: 100%;
      height: 100%;
    }

    .homepage__main-news__content {
      position: relative;
      margin-top: 0;
      padding: 50px 50px 50px 60px;
      width: 100%;
      height: auto;
    }

    .news-content__date {
      top: 50px;
      left: 10px;
    }
  }

  .homepage__second-news {
    flex-direction: column;
    align-items: center;
    .homepage__second-news__content {
      width: 100%;
      height: auto;
    }

    .img__wrap__cover {
      width: 70%;
      height: auto;
      z-index: 1;
    }
    .homepage__second-news__image {
      width: 100%;
      height: 100%;
    }
  }

  .homepage__third-news {
    flex-direction: column-reverse;
    align-items: center;

    .img__wrap__cover {
      width: 70%;
      height: auto;
    }

    .homepage__third-news__image {
      width: 100%;
      height: 100%;
    }
    .homepage__third-news__content {
      width: 100%;
      height: auto;
    }
  }
}

.news-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 50px;
}

.news-box {
  text-decoration: none;
  margin: 75px 5px 0 5px;

  &:hover {
    .news-box__img {
      transform: scale(1.05);
      &::before {
        opacity: 1;
      }
    }

    // .news-box__title {
    //   color: #929292;
    // }

    .news-box__date {
      background-color: #ffd105;

      .news-date__day,
      .news-date__month {
        color: #fff;
      }
      .news-date__day {
        &::after {
          background-color: #fff;
        }
      }
    }
  }
}

.news-box__img__wrap {
  overflow: hidden;
}

.news-box__img {
  width: 100%;
  // height: 285px;
  height: 380px;

  background-image: url(../../assets/img/jpg/news-box.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: transform 0.3s ease;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.1);
    opacity: 0;
    transition: opacity 0.3s ease;
  }
}

.news-box--small {
  // width: 420px;
  width: 100%;
}

.news-box__content {
  display: flex;
  align-items: center;
  margin-top: 35px;
}

.news-box__title {
  font-size: 22px;
  font-family: "Ride Extrabold", sans-serif;
  text-decoration: none;
  color: #000;
  margin: 0;
  transition: color 0.3s ease;
}

.news-box__date {
  position: static;
  white-space: nowrap;
  margin-right: 20px;
  transition: all 0.5s ease;
  padding: 5px;
}

.news-box--big {
  width: 895px;
  position: relative;

  &:hover {
    .news-box__title {
      color: #fff;
    }
  }

  .news-box__img {
    height: 380px;
    padding: 0 25px 40px 25px;
    display: flex;
    align-items: flex-end;
  }

  .news-box__content {
    position: absolute;
    bottom: 70px;
    left: 45px;
  }

  .news-box__title {
    color: #fff;
  }
  .news-box__date {
    color: #fff;

    .news-date__day {
      &::after {
        background: #fff;
      }
    }
  }
}

.news-tags {
  margin-top: 95px;
}

.news-link {
  text-decoration: none;
}

.news-box {
  text-decoration: none;

  &:nth-of-type(2) {
    .news-box__img {
      background-image: url(../../assets/img/jpg/news-box-2.jpg);
    }
  }

  &:nth-of-type(3) {
    .news-box__img {
      background-image: url(../../assets/img/jpg/news-box-3.jpg);
    }
  }

  &:nth-of-type(4) {
    .news-box__img {
      background-image: url(../../assets/img/jpg/news-box-big.jpg);
    }
  }

  &:nth-of-type(5) {
    .news-box__img {
      background-image: url(../../assets/img/jpg/news-box-4.jpg);
    }
  }

  .news-box__date {
    .news-date__day,
    .news-date__month {
      font-family: "Ride Extrabold", sans-serif;
    }
  }
}

@media screen and (min-width: $screen-sm) {
  .news-category__content {
    margin: 80px 0;
    // padding: 0 30px;
  }

  .img__wrap__cover {
    position: relative;
    overflow: hidden;
    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($color: #000000, $alpha: 0.1);
      z-index: 20;
      opacity: 0;
      transition: opacity 0.3s ease;
    }
  }

  .news-list__tab {
    font-size: 18px;
  }

  .news-list {
    .homepage__main-news {
      flex-direction: row;
      .img__wrap__cover {
        width: 445px;
        height: 307px;
      }
      .homepage__main-news__image {
        width: 100%;
        height: 100%;
      }

      .homepage__main-news__content {
        position: relative;
        margin-top: 0;
        padding: 20px 25px 25px 60px;
        width: 500px;
        height: 307px;

        .news-content__date {
          top: 20px;
          left: 15px;
        }
      }
    }

    .homepage__second-news {
      flex-direction: row;
      margin-top: 20px;
      .homepage__second-news__content {
        width: 500px;
        height: 307px;
        padding: 30px;

        .news-content__date {
          top: 30px;
          left: -15px;
        }
      }

      .img__wrap__cover {
        width: 445px;
        height: 307px;
        z-index: 1;
        margin-top: 0;
      }

      .homepage__second-news__image {
        width: 100%;
        height: 100%;
        margin: 0;
      }
    }

    .homepage__third-news {
      flex-direction: row;
      margin-top: 20px;
      .img__wrap__cover {
        width: 445px;
        height: 307px;
      }
      .homepage__third-news__image {
        width: 100%;
        height: 100%;
      }
      .homepage__third-news__content {
        padding: 30px 30px 30px 60px;
        width: 500px;
        height: 307px;

        .news-content__date--third {
          top: 30px;
          left: 15px;
        }
      }
    }
  }

  .homepage__main-news__title {
    font-size: 26px;
    line-height: 1.2;
  }

  .homepage__main-news__paragraph {
    font-size: 16px;
    line-height: 1.8;
  }

  .homepage__second-news__title {
    font-size: 26px;
    line-height: 1.2;
  }

  .homepage__second-news__paragraph {
    font-size: 16px;
    line-height: 1.8;
  }

  .homepage__third-news__title {
    font-size: 26px;
    line-height: 1.2;
  }

  .homepage__third-news__paragraph {
    font-size: 16px;
    line-height: 1.8;
  }

  .news-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 50px;
  }

  .news-box {
    text-decoration: none;
    margin-top: 75px;
    margin-left: auto;
    margin-right: auto;
  }

  .news-box__img {
    width: 100%;
    height: 285px;
    background-image: url(../../assets/img/jpg/news-box.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .news-box--small {
    // width: 420px;
    width: 50%;
    padding: 20px;
  }

  .news-box__content {
    display: flex;
    align-items: center;
    margin-top: 35px;
  }

  .news-box__title {
    font-size: 20px;
    font-family: "Ride Extrabold", sans-serif;
    text-decoration: none;
    color: #000;
    margin: 0;
  }

  .news-box__date {
    position: static;
    white-space: nowrap;
    margin-right: 20px;
  }

  .news-box--big {
    // width: 895px;
    width: 100%;
    padding: 20px;
    // order: 2;

    .news-box__img {
      height: 380px;
      padding: 0 25px 40px 25px;
      display: flex;
      align-items: flex-end;
    }
    .news-box__title {
      color: #fff;
    }
    .news-box__date {
      color: #fff;

      .news-date__day {
        &::after {
          background: #fff;
        }
      }
    }
  }
}

@media screen and (min-width: $screen-md) {
  .news-list__tab {
    padding: 0 30px;
  }
  .news-category__content {
    margin: 125px 0;
    // padding: 0 30px;
  }

  .news-list {
    .homepage__main-news {
      .img__wrap__cover {
        width: 540px;
        height: 370px;
      }
      .homepage__main-news__image {
        width: 100%;
        height: 100%;
      }

      .homepage__main-news__content {
        position: relative;
        margin-top: 0;
        padding: 20px 25px 25px 60px;
        width: 507px;
        height: 370px;
        .news-content__date {
          top: 20px;
          left: 15px;
        }
      }
    }

    .homepage__second-news {
      margin-top: 0;
      .homepage__second-news__content {
        width: 507px;
        padding: 30px;

        .news-content__date {
          top: 30px;
          left: -15px;
        }
      }
      .img__wrap__cover {
        width: 540px;
        height: 370px;
        margin-top: -20px;
      }
      .homepage__second-news__image {
        width: 100%;
        height: 100%;
        z-index: 1;
      }
    }

    .homepage__third-news {
      margin-top: 0;
      .img__wrap__cover {
        width: 405px;
        height: 305px;
      }
      .homepage__third-news__image {
        width: 100%;
        height: 100%;
      }
      .homepage__third-news__content {
        padding: 30px 30px 30px 60px;
        width: 540px;

        .news-content__date--third {
          top: 30px;
          left: 15px;
        }
      }
    }
  }

  .homepage__main-news__title {
    font-size: 24px;
    line-height: 40px;
  }

  .homepage__main-news__paragraph {
    font-size: 16px;
    line-height: 1.8;
  }

  .homepage__second-news__title {
    font-size: 24px;
    line-height: 1.2;
  }

  .homepage__second-news__paragraph {
    font-size: 16px;
    line-height: 1.8;
  }

  .homepage__third-news__title {
    font-size: 24px;
    line-height: 1.2;
  }

  .homepage__third-news__paragraph {
    font-size: 16px;
    line-height: 1.8;
  }

  .news-row {
    flex-wrap: wrap;
    // justify-content: space-between;
    margin-top: 50px;
  }

  .news-box {
    text-decoration: none;
    // margin-top: 75px;
    margin: 75px 0 0 0;
  }

  .news-box--small {
    // width: 420px;
    width: 33.333%;
  }

  .news-box__title {
    font-size: 20px;
  }

  .news-box__date {
    position: static;
    white-space: nowrap;
    margin-right: 20px;
  }

  .news-box--big {
    // width: 895px;
    width: 66.666%;

    .news-box__date {
      .news-date__day {
        &::after {
          background: #fff;
        }
      }
    }
  }
}

@media screen and (min-width: $screen-lg) {
  .news-list {
    .homepage__last-newses {
      padding: 0;
    }

    .homepage__main-news {
      margin-top: 0;

      .img__wrap__cover {
        width: 670px;
        height: 460px;
        overflow: hidden;
        z-index: 10;
      }

      .homepage__main-news__image {
        width: 100%;
        height: 100%;
        transition: transform 0.3s ease;
      }

      .homepage__main-news__content {
        position: relative;
        margin-top: 0;
        padding: 35px 35px 35px 80px;
        width: 590px;
        height: 460px;

        .news-content__date {
          top: 35px;
          left: 30px;
        }
      }
      .homepage__main-news__paragraph {
        padding-left: 0 !important;
      }
    }

    .homepage__second-news {
      .homepage__second-news__content {
        width: 620px;
        height: 410px;
        padding-right: 80px;
        padding-top: 70px;

        .news-content__date {
          top: 70px;
          left: -20px;
        }
      }

      .img__wrap__cover {
        width: 670px;
        height: 460px;
        overflow: hidden;
        z-index: 1;
        margin-top: -50px;
      }

      .homepage__second-news__image {
        width: 100%;
        height: 100%;
        transition: transform 0.3s ease;
      }
    }

    .homepage__third-news {
      .img__wrap__cover {
        width: 405px;
        height: 305px;
        overflow: hidden;
      }

      .homepage__third-news__image {
        width: 100%;
        height: 100%;
        transition: transform 0.3s ease;
      }
      .homepage__third-news__content {
        width: 670px;
        height: 305px;
        padding-left: 80px;
        padding-top: 50px;
        .news-content__date--third {
          top: 50px;
          left: 30px;
        }
      }
    }
  }

  .homepage__main-news__title {
    font-size: 28.5px;
    line-height: 40px;
  }

  .homepage__main-news__paragraph {
    font-size: 17px;
    line-height: 1.8;
  }

  .homepage__second-news__title {
    font-size: 28.5px;
    line-height: 1.2;
  }

  .homepage__second-news__paragraph {
    font-size: 17px;
    line-height: 1.8;
  }

  .homepage__third-news__title {
    font-size: 28.5px;
    line-height: 1.2;
  }

  .homepage__third-news__paragraph {
    font-size: 17px;
    line-height: 1.8;
  }

  .news-row {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    margin-top: 50px;
  }

  .news-box__img {
    width: 100%;
    height: 285px;
    background-image: url(../../assets/img/jpg/news-box.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .news-box--small {
    // width: 420px;
    width: 33.333%;
    padding: 20px;
  }

  .news-box__content {
    display: flex;
    align-items: center;
    margin-top: 35px;
  }

  .news-box__title {
    font-size: 25px;
    font-family: "Ride Extrabold", sans-serif;
    text-decoration: none;
    color: #000;
    margin: 0;
  }

  .news-box__date {
    position: static;
    white-space: nowrap;
    margin-right: 20px;
  }

  .news-box--big {
    // width: 895px;
    width: 66.666%;
    padding: 20px;

    .news-box__img {
      height: 380px;
      padding: 0 25px 40px 25px;
      display: flex;
      align-items: flex-end;
    }
    .news-box__title {
      color: #fff;
    }
    .news-box__date {
      color: #fff;

      .news-date__day {
        &::after {
          background: #fff;
        }
      }
    }
  }
}
