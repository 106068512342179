footer {
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  margin-bottom: 50px;
}

.navbar__footer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navbar__menu__footer {
  display: flex;

  .navbar__link--footer {
    text-decoration: none;
    height: auto;
    margin: 0;
    color: $colorText;
    margin-bottom: 25px;
    font-family: "Ride", sans-serif;
    font-size: 18px;
    transition: transform 0.3s ease-out;
    display: inline-block;

    &:hover {
      &::before {
        top: 20px;
      }

      transform: translate(10px, 0);
    }
  }

  .--main-link {
    font-family: "Ride Bold", sans-serif;
    font-size: 18px;
  }
}

.navbar__menu__wrapper {
  display: flex;
  width: 100%;
}

.navbar__menu__footer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}

.navbar__menu__row {
  display: flex;
  flex-direction: column;
  margin-right: 0;
}

.copyright {
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;

  span {
    font-family: "Ride Bold", sans-serif;
    font-size: 18px;
  }
}

.navbar__menu__social {
  display: flex;
  margin-top: 30px;

  a {
    text-decoration: none;
    padding: 25px;

    img {
      transition: transform 0.3s ease-out;
    }

    &:hover {
      img {
        transform: scale(1.3) translateZ(0);
      }
    }
  }
}

.privacy-policy {
  text-decoration: none;
  color: $colorText;
  font-family: "Ride Bold", sans-serif;
  font-size: 18px;
}

@media screen and (min-width: $screen-sm) {
  .navbar__menu__social {
    flex-direction: row;
    a {
      margin-bottom: 0;
    }
  }

  footer {
    margin-top: 100px;
  }

  .navbar__footer {
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
  }

  .navbar__menu__social {
    margin-top: 0;
    a {
      text-decoration: none;
      // margin-left: 20px;
      // margin-bottom: 25px;
    }
  }

  .copyright {
    margin-top: 80px;
  }

  .navbar__menu__row {
    margin-right: 70px;
  }

  .navbar__menu__footer {
    display: flex;
    width: 100%;
    justify-content: flex-start;
  }
}

@media screen and (min-width: $screen-md) {
  footer {
    margin-top: 120px;
    margin-bottom: 80px;
  }
  .navbar__menu__row {
    margin-right: 120px;
  }
}

@media screen and (min-width: $screen-lg) {
  footer {
    margin-top: 150px;
  }

  .navbar__menu__row {
    margin-right: 140px;
  }
}
