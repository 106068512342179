.news-single {
  padding: 0 30px;
  position: relative;

  .news-date {
    top: 5px;
    left: -30px;
  }
}

.news-date {
  font-size: 25px;
  color: $colorText;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: absolute;
}

.news-date__day {
  position: relative;
  padding-bottom: 8px;
  font-family: "Ride Bold", sans-serif;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 3px;
    background: #000;
  }
}

.news-date__month {
  padding-top: 5px;
  font-family: "Ride Bold", sans-serif;
}

.news-single__title {
  margin-top: 0;
}

.news__subtitle {
  font-size: 25px;
  font-family: "Ride Extrabold", sans-serif;
  margin-bottom: 10px;
  line-height: 40px;
}

.news__paragraph {
  font-size: 18px;
  margin-top: 0;
}

.news-single__img {
  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;
}

.news-single__nav {
  margin-top: 50px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: flex-start;
}

.news-single__nav__back {
  order: 2;
  font-size: 16px;
  font-family: "Ride Extrabold", sans-serif;
  text-decoration: none;
  color: $colorLink2;
  margin-right: 60px;
  // margin-top: 40px;
  margin-bottom: 40px;
  display: inline-block;
  padding-left: 35px;
  background-image: url(../../assets/img/png/link-arrow-left.png);
  background-repeat: no-repeat;
  background-position: 5% 70%;
  transition: background-position 0.3s ease-out;
  cursor: pointer;
  &:hover {
    background-position: 0% 70%;
  }
}

.news-single__tags {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
}

.news-single__tags__title {
  font-size: 18px;
  font-family: "Ride Extrabold", sans-serif;
  white-space: nowrap;
}

.news-single__tags__list {
  display: flex;
  list-style: none;
  padding: 0;
  flex-wrap: wrap;
}

.news-single__tag__link {
  text-decoration: none;
  font-size: 16px;
  color: $colorTag;
  padding: 10px 25px;
  border: 1px solid $colorTagBorder;
  font-weight: bold;
  // margin-left: 18px;
  display: inline-block;
  margin-bottom: 15px;
  font-family: "Ride Bold", sans-serif;

  &:hover {
    background-color: $colorTagBcg;
    border-color: $colorTagBcg;
  }
}

.fb-comments {
  margin-top: 100px;
}

.fb-info {
  font-size: 13px;
  color: $colorFbInfo;
  margin-top: 20px;
  display: inline-block;
  font-family: "Ride", sans-serif;
  line-height: 17.5px;

  a {
    color: $colorFb;
    text-decoration: none;
  }
}

@media screen and (min-width: $screen-sm) {
  .news-single {
    .block-img {
      flex-direction: row;
    }
  }

  .news-date {
    top: 70px;
    left: 45px;
  }

  .news-single__tags {
    display: flex;
    flex-direction: row;
  }

  .news-single__nav {
    margin-top: 50px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
  }

  .news-single__nav__back {
    margin-bottom: 0;
  }
}

@media screen and (min-width: $screen-md) {
  .news-date {
    top: 75px;
    left: 80px;
  }
}

@media screen and (min-width: $screen-lg) {
  .news-single__tag__link {
    margin-bottom: 0;
  }
}
