.lease__wrapper {
  display: flex;
  flex-direction: column;
}

.lease__lists {
  margin-bottom: 100px;
  margin-top: 50px;
}

.lease__list {
  margin-bottom: 50px;
}

.lease__img {
  margin-right: 40px;
  margin-bottom: 30px;
}

.customer-journey__infographic {
  width: 100%;
}

@media screen and (min-width: $screen-sm) {
  .lease__wrapper {
    flex-direction: row;
  }

  .lease__img {
    margin-bottom: 0;
  }
}
