$colorMenu: #7b7b7b;
$colorNavbar: #f7f7f7;
$colorMenuHover: #000;
$colorBorder: #ffd105;
$colorText: #000;
$colorParagraph: #262626;
$colorDecor: #000;
$colorContent: #fff;
$colorBtnBcg: #fffef7;
$colorBtnBorder: #ffd105;
$colorBlackBtn: #000;
$colorBlackBtnText: #fff;
$colorInfoBox: #fffef7;
$colorInfoBoxBorder: #ffd105;
$colorStep: #ffd105;
$colorStepNumber: #fff;
$colorLink: #4c9ae2;
$colorInputBorder: #e2e2e2;
$colorPlaceholder: #7b7b7b;
$colorDepartments: #262626;
$colorCommunicat: #929292;
$colorInactiveTabBcg: #e7e7e7;
$colorActiveTabBcg: #fff;
$colorInactiveTab: #7b7b7b;
$colorActiveTab: #000;
$colorLink2: #7b7b7b;
$colorTag: #7b7b7b;
$colorTagBorder: #e2e2e2;
$colorTagBcg: #f0f0f0;
$colorFbInfo: #9c9c9c;
$colorFb: #3a5897;
$colorNewsBorder: #dbdbdb;
$colorDateActiveBcg: #ffd105;
$colorDateActive: #fff;
$colorHomepageNewsContent: #929292;
