.main {
  margin-top: 96px;
  overflow: hidden;
  > .container {
    padding: 0;
  }
}

.main__wrapper {
  display: flex;
  width: 100%;
  height: 629px;
  align-items: center;
}

.main__title {
  position: absolute;
  margin: 0;
  margin-left: 30px;
  display: inline-flex;
  //   align-items: flex-start;
  width: 50%;
  min-width: 446px;
  padding-right: 30px;
  color: #fff;

  &::before {
    content: "";
    left: 0;
    top: 0;
    height: 7px;
    min-width: 40px;
    margin-right: 20px;
    margin-top: 40px;
    background: #fff;
  }
}

.main__image {
  width: auto;
  height: 100%;
}

// homepage news columns
// left column with news dates

// .left-column {
//   min-width: 330px;
//   width: 330px;
//   margin-right: 40px;
// }

// .homepage-news__columns {
//   display: flex;
// }

.homepage-news {
  position: relative;
}

.homepage-news__list {
  width: 330px;
  position: absolute;
  top: -115px;
  left: 0;
  display: none;
}

.homepage-news__list__single {
  display: flex;
  align-items: center;
  border-bottom: 2px solid $colorNewsBorder;
  text-decoration: none;
  color: #000;

  &:last-of-type {
    border-bottom: none;
  }
}

.homepage-news__date {
  position: static;
  padding: 10px 9px;
  margin: 10px 10px 10px 0;
  white-space: nowrap;
  font-size: 20px;
  transition: all 0.5s ease;
}

.homepage-news__content {
  font-size: 20px;
  font-family: "Ride Bold", sans-serif;
  line-height: 24px;
  transition: all 0.3s ease;
}

// right column - blocks with img

// .right-column {
//   padding-right: 30px;
//   width: 100%;
// }

.homepage__main-news {
  display: flex;
  flex-direction: column-reverse;
  margin-top: -90px;
  align-items: center;
}

.homepage__main-news__content {
  background-color: #fff;
  padding: 50px;
  margin-top: -70px;
  width: 100%;

  .homepage__main-news__title {
    display: inline-flex;
    line-height: 1.2;
    &::before {
      content: "";
      left: 0;
      top: 0;
      min-width: 22px;
      height: 3px;
      margin-right: 10px;
      margin-top: 20px;
      background-color: #000;
    }
  }
}

.homepage__main-news__title {
  font-size: 30px;
  // font-family: "Ride Extrabold", sans-serif;
  font-family: "Ride Extrabold", sans-serif;
  line-height: 40px;
  margin: 0;
  transition: all 0.3s ease;
}

.homepage__main-news__paragraph {
  font-size: 18px;
  font-family: "Ride", sans-serif;
  color: $colorParagraph;
  padding-left: 30px;
}

.img__wrap__cover {
  width: 70%;
  height: auto;
  z-index: 10;
  margin: 0 auto;
}

.homepage__main-news__image {
  width: 100%;
  height: 100%;
}

.homepage__last-newses {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  // padding-right: 30px;
}

.homepage__second-news {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.homepage__second-news__content {
  width: 100%;
  padding: 50px;
  padding-left: 60px;
  position: relative;
}

.homepage__second-news__title {
  font-size: 30px;
  // font-family: "Ride Extrabold", sans-serif;
  font-family: "Ride Extrabold", sans-serif;
  margin-top: 0;
  transition: all 0.3s ease;
}

.homepage__second-news__paragraph {
  font-size: 18px;
  font-family: "Ride", sans-serif;
  color: $colorParagraph;
}

.news-content__date {
  position: absolute;
  top: 50px;
  left: 10px;
  transition: all 0.5s ease;
  font-size: 22px;
  padding: 5px;

  .news-date__day,
  .news-date__month {
    font-family: "Ride Extrabold", sans-serif;
  }
}

.homepage__second-news__image {
  // margin-top: -50px;
  width: 100%;
  height: 100%;
}

.homepage__third-news {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}

.homepage__third-news__image {
  width: 100%;
  height: 100%;
  position: relative;
}

.homepage__third-news__content {
  padding: 50px;
  padding-left: 60px;
  width: 100%;
  position: relative;
}

.homepage__third-news__title {
  font-size: 30px;
  // font-family: "Ride Extrabold", sans-serif;
  font-family: "Ride Extrabold", sans-serif;
  margin-top: 0;
  transition: all 0.3s ease;
}

.news-title__link {
  color: #000;
  text-decoration: none;
  font-family: "Ride Extrabold", sans-serif;
}

.homepage__third-news__paragraph {
  font-size: 18px;
  font-family: "Ride", sans-serif;
  color: $colorParagraph;
}

.news-content__date--third {
  position: absolute;
  top: 50px;
  left: 10px;
  padding: 5px;
  transition: all 0.5s ease;
  font-size: 22px;

  .news-date__day,
  .news-date__month {
    font-family: "Ride Extrabold", sans-serif;
  }
}

.homepage__main-news__image,
.homepage__second-news__image,
.homepage__third-news__image {
  transition: transform 0.3s ease;
}

.news-link {
  &:hover {
    .news-content__date--third,
    .news-content__date {
      background-color: $colorDateActiveBcg;
      .news-date__day,
      .news-date__month {
        color: $colorDateActive;
      }
      .news-date__day {
        &:after {
          background-color: $colorDateActive;
        }
      }
    }

    // .homepage__main-news__title,
    // .homepage__second-news__title,
    // .homepage__third-news__title {
    //   color: #929292;
    // }

    // .homepage__main-news__title {
    //   &::before {
    //     background: #929292;
    //   }
    // }

    .homepage__main-news__image,
    .homepage__second-news__image,
    .homepage__third-news__image {
      transform: scale(1.05);
    }

    .img__wrap__cover {
      &::before {
        opacity: 1;
      }
    }
  }
}

.banners-little {
  .related__card {
    &:first-of-type {
      .related__card__img {
        background-image: url(../../assets/img/jpg/related-card-home-1.jpg);
      }
    }

    &:nth-of-type(2) {
      .related__card__img {
        background-image: url(../../assets/img/jpg/related-card-01.jpg);
      }
    }

    &:nth-of-type(3) {
      .related__card__img {
        background-image: url(../../assets/img/jpg/related-card-home-2.jpg);
      }
    }
  }
}

@media screen and (min-width: $screen-sm) {
  .main {
    margin-top: 96px;
  }

  .main__wrapper {
    // display: flex;
    // flex-direction: row;
    // width: 100%;
    // height: 419px;
    // align-items: center;
  }

  .main__title {
    // position: static;
    color: #fff;
    margin: 0;

    // display: inline-flex;
    //   align-items: flex-start;
    // width: 311px;
    // min-width: 311px;
    // padding-right: 30px;
    padding-left: 20px;

    margin-top: -80px;

    &::before {
      content: "";
      left: 0;
      top: 0;
      height: 7px;
      min-width: 40px;
      margin-right: 20px;
      margin-top: 40px;
      background: #fff;
    }
  }

  .main__image {
    // max-width: calc(100% - 311px);
  }

  .homepage__main-news {
    flex-direction: row;
    text-decoration: none;
    margin-bottom: 20px;
  }
  .homepage__main-news__content {
    padding: 25px;
    margin-top: 0;
    width: 500px;
    height: 307px;

    .homepage__main-news__title {
      &::before {
        margin-top: 15px;
      }
    }

    .homepage__main-news__paragraph {
      padding-left: 30px;
    }
  }

  .img__wrap__cover {
    width: 445px;
    height: 307px;
    z-index: 10;
  }

  .homepage__main-news__image {
    width: 100%;
    height: 100%;
  }

  .homepage__second-news {
    flex-direction: row;
    text-decoration: none;
    margin-bottom: 20px;
  }

  .homepage__second-news__content {
    width: 500px;
    height: 307px;
    padding: 25px;
  }

  .homepage__second-news__image {
    width: 100%;
    height: 100%;
    // margin-top: -50px;
  }

  .homepage__third-news {
    flex-direction: row;
    text-decoration: none;
  }

  .homepage__third-news__image {
    width: 100%;
    height: 100%;
  }

  .homepage__third-news__content {
    padding: 25px;
    padding-left: 60px;
    width: 500px;
    height: 307px;
    position: relative;
  }

  .news-content__date {
    position: absolute;
    top: 25px;
    left: -20px;
    font-size: 20px;
  }

  .news-content__date--third {
    position: absolute;
    top: 25px;
    left: 15px;
    font-size: 20px;
  }

  .homepage-news__list {
    width: 280px;
    position: absolute;
    top: -115px;
    left: 0;
    display: none;
  }

  .homepage-news__list__single {
    display: flex;
    align-items: center;
    border-bottom: 2px solid $colorNewsBorder;

    &:last-of-type {
      border-bottom: none;
    }

    &:hover {
      .homepage-news__date {
        background-color: $colorDateActiveBcg;
        .news-date__day,
        .news-date__month {
          color: $colorDateActive;
        }
      }

      .news-date__day:after {
        background-color: $colorDateActive;
      }

      // .homepage-news__content {
      //   color: $colorHomepageNewsContent;
      // }
    }
  }

  .homepage-news__date {
    position: static;
    // padding: 20px 9px;
    // margin: 10px 10px 10px 0;
    white-space: nowrap;
    font-size: 18px;
  }

  .homepage-news__content {
    font-size: 18px;
    line-height: 24px;
  }
}

@media screen and (min-width: $screen-md) {
  .main {
    margin-top: 96px;
  }

  .main__wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 506px;
    align-items: center;
  }

  .main__title {
    position: static;
    margin: 0;
    margin-top: -80px;
    display: inline-flex;
    //   align-items: flex-start;
    width: 462px;
    min-width: 462px;
    padding-right: 30px;
    color: #000;
    &::before {
      content: "";
      left: 0;
      top: 0;
      height: 7px;
      min-width: 40px;
      margin-right: 20px;
      margin-top: 40px;
      background: $colorDecor;
    }
  }

  .main__image {
    max-width: calc(100% - 402px);
  }

  .homepage__main-news {
    margin-bottom: 0;
  }

  .homepage__main-news__content {
    padding: 30px;
    padding-left: 15px;
    margin-top: -70px;
    width: 403px;

    .homepage__main-news__title {
      font-size: 24px;
      &::before {
        min-width: 18px;
      }
    }

    .homepage__main-news__paragraph {
      padding-left: 25px;
    }
  }

  .homepage__main-news__image {
    // width: 427px;
    // height: 295px;
    width: 100%;
    height: 100%;
    z-index: 10;
  }

  .homepage__second-news {
    margin-bottom: 0;

    .img__wrap__cover {
      width: 470px;
      height: 350px;
    }
  }

  .homepage__second-news__title {
    font-size: 24px;
  }

  .homepage__second-news__content {
    width: 437px;
    height: 350px;
    padding: 30px;
  }

  .homepage__second-news__image {
    // width: 540px;
    // height: 370px;
    width: 100%;
    height: 100%;
    // margin-top: -50px;
  }

  .homepage__third-news {
    .img__wrap__cover {
      width: 530px;
      height: 360px;
    }
  }

  .homepage__third-news__title {
    font-size: 24px;
  }

  .homepage__third-news__image {
    // width: 540px;
    // height: 370px;
    width: 100%;
    height: 100%;
  }

  .homepage__third-news__content {
    padding: 30px;
    padding-left: 60px;
    width: 470px;
    position: relative;
  }

  .news-content__date {
    position: absolute;
    top: 25px;
    left: -20px;
    font-size: 20px;
  }

  .news-content__date--third {
    position: absolute;
    top: 25px;
    left: 15px;
    font-size: 20px;
  }

  .homepage-news__list {
    width: 280px;
    position: absolute;
    top: -90px;
    left: 0;
    display: block;
  }

  .homepage-news__list__single {
    display: flex;
    align-items: center;
    border-bottom: 2px solid $colorNewsBorder;

    &:last-of-type {
      border-bottom: none;
    }
  }

  .homepage-news__date {
    position: static;
    // padding: 20px 9px;
    // margin: 10px 10px 10px 0;
    white-space: nowrap;
    font-size: 18px;
  }

  .homepage-news__content {
    font-size: 18px;
    line-height: 24px;
  }

  .homepage-news__list--active {
    .homepage-news__date {
      background-color: $colorDateActiveBcg;
      .news-date__day,
      .news-date__month {
        color: $colorDateActive;
      }
    }

    .news-date__day:after {
      background-color: $colorDateActive;
    }

    .homepage-news__content {
      color: $colorHomepageNewsContent;
    }
  }
}

@media screen and (min-width: $screen-lg) {
  .main {
    margin-top: 96px;
  }

  .main__wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 629px;
    align-items: center;
  }

  .main__title {
    margin: 0;
    margin-top: -80px;
    display: inline-flex;
    //   align-items: flex-start;
    width: 530px;
    min-width: 530px;
    padding-right: 30px;

    &::before {
      content: "";
      left: 0;
      top: 0;
      height: 7px;
      min-width: 40px;
      margin-right: 20px;
      margin-top: 40px;
      background: $colorDecor;
    }
  }

  .main__image {
    max-width: calc(100% - 530px);
  }

  .homepage__last-newses {
    padding-right: 140px;
  }

  .homepage__main-news__content {
    padding: 50px;
    margin-top: -70px;
    width: 530px;

    .homepage__main-news__title {
      font-size: 28.5px;
      &::before {
        margin-top: 20px;
      }
    }
  }

  .homepage__main-news__image {
    width: 530px;
    height: 365px;
    z-index: 10;
  }

  .homepage__second-news__content {
    width: 450px;
    height: 405px;
    padding: 40px;

    .news-content__date {
      top: 40px;
    }
  }

  .homepage__second-news__title {
    margin-bottom: 0;
    font-size: 28.5px;
  }

  .homepage__second-news__image {
    width: 670px;
    height: 460px;
    margin-top: -50px;
  }

  .homepage__third-news__image {
    width: 670px;
    height: 460px;
  }

  .homepage__third-news__content {
    padding: 50px;
    padding-left: 100px;
    width: 670px;
    height: 460px;
    position: relative;
  }

  .homepage__third-news__title {
    margin-bottom: 0;
    font-size: 28.5px;
  }

  .news-content__date {
    position: absolute;
    top: 45px;
    left: -15px;
    padding: 5px;
    font-size: 22px;
  }

  .news-content__date--third {
    position: absolute;
    top: 45px;
    left: 45px;
    padding: 5px;
    font-size: 22px;
  }

  .homepage-news__list {
    width: 330px;
    position: absolute;
    top: -115px;
    left: 0;
  }

  .homepage-news__list__single {
    display: flex;
    align-items: center;
    border-bottom: 2px solid $colorNewsBorder;

    &:last-of-type {
      border-bottom: none;
    }
  }

  .homepage-news__date {
    position: static;
    // padding: 20px 9px;
    // margin: 15px 15px 15px 0;
    white-space: nowrap;
    font-size: 20px;
    margin-right: 15px;
  }

  .homepage-news__content {
    font-size: 20px;
    line-height: 30px;
  }

  .homepage-news__list--active {
    .homepage-news__date {
      background-color: $colorDateActiveBcg;
      .news-date__day,
      .news-date__month {
        color: $colorDateActive;
      }
    }

    .news-date__day:after {
      background-color: $colorDateActive;
    }

    .homepage-news__content {
      color: $colorHomepageNewsContent;
    }
  }

  .homepage__last-newses {
    .homepage__main-news {
      .img__wrap__cover {
        width: 470px;
        height: 340px;
        z-index: 20;
      }

      .homepage__main-news__image {
        width: 100%;
        height: 100%;
      }

      .homepage__main-news__content {
        width: 490px;
        height: 350px;
        margin-top: -130px;
        padding: 40px;
        padding-left: 20px;

        .homepage__main-news__paragraph {
          padding-left: 35px;
        }
      }
    }

    .homepage__second-news {
      .img__wrap__cover {
        width: 590px;
        height: 404px;
        margin-top: -50px;
      }

      .homepage__second-news__image {
        width: 100%;
        height: 100%;
        margin: 0;
      }

      .homepage__second-news__content {
        width: 490px;
        height: 350px;
      }
    }

    .homepage__third-news {
      .img__wrap__cover {
        width: 590px;
        height: 404px;
      }

      .homepage__third-news__image {
        width: 100%;
        height: 100%;
        margin: 0;
      }

      .homepage__third-news__content {
        width: 590px;
        height: 404px;
      }
    }
  }
}

@media screen and (min-width: $screen-xlg) {
  .main__title {
    width: 550px;
    min-width: 550px;
  }
  .homepage-news__list {
    top: -100px;
  }

  .homepage__last-newses {
    .homepage__main-news {
      .img__wrap__cover {
        width: 530px;
        height: 365px;
        z-index: 1;
      }

      .homepage__main-news__image {
        width: 100%;
        height: 100%;
      }

      .homepage__main-news__content {
        width: 530px;
        height: 455px;
      }
    }

    .homepage__second-news {
      .img__wrap__cover {
        width: 670px;
        height: 460px;
        margin-top: -50px;
      }

      .homepage__second-news__image {
        width: 100%;
        height: 100%;
        margin: 0;
      }

      .homepage__second-news__content {
        width: 470px;
        height: 405px;
      }
    }

    .homepage__third-news {
      .img__wrap__cover {
        width: 670px;
        height: 460px;
      }

      .homepage__third-news__image {
        width: 100%;
        height: 100%;
        margin: 0;
      }

      .homepage__third-news__content {
        width: 670px;
        height: 460px;
      }
    }
  }
}
