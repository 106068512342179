.categories {
  margin-top: 96px;
  display: flex;
  height: auto;
  flex-wrap: wrap;
}

.category__wrap {
  width: 100%;
  height: 285px;
  overflow: hidden;

  &:hover {
    .category__img {
      transform: scale(1.05);

      &::before {
        opacity: 1;
      }
    }
  }

  &:first-of-type {
    .category__img {
      background-image: url(../../assets/img/jpg/category-01.jpg);
      background-position: center right;
    }
  }

  &:nth-of-type(2) {
    .category__img {
      background-image: url(../../assets/img/jpg/category-02.jpg);
    }
  }

  &:nth-of-type(3) {
    .category__img {
      background-image: url(../../assets/img/jpg/category-03.jpg);
    }
  }

  &:nth-of-type(4) {
    .category__img {
      background-image: url(../../assets/img/jpg/category-04.jpg);
    }
  }

  .category__img {
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100%;
    transition: transform 0.3s ease;
    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($color: #000000, $alpha: 0.11);
      opacity: 0;
      transition: opacity 0.3s ease;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 25%;
      background-image: linear-gradient(
        top,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.41) 100%
      );
    }
  }

  .category {
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    // padding: 50px 25px;
    text-decoration: none;
    position: relative;
  }
}

.category__title {
  color: $colorContent;
  display: inline-flex;
  align-items: center;
  font-family: "Ride Extrabold", sans-serif;
  font-size: 30px;
  line-height: 40px;
  z-index: 10;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 40px 20px 40px 20px;

  &::before {
    content: "";
    width: 20px;
    height: 3px;
    align-self: flex-start;
    background: $colorContent;
    margin-right: 15px;
    margin-top: 20px;
  }
}

@media screen and (min-width: $screen-tablet) {
  .categories {
    height: auto;
  }

  .category__wrap {
    height: 530px;
    width: 50%;
  }
}

@media screen and (min-width: $screen-sm) {
}

@media screen and (min-width: $screen-md) {
  .categories {
    height: 690px;
  }

  .categories {
    // height: 600px;
  }

  .category__wrap {
    width: 25%;
    height: 100%;
  }
}

@media screen and (min-width: $screen-lg) {
  .categories {
    height: 860px;
  }
}
