.info-box {
  background-color: $colorInfoBox;
  border: 1px solid $colorInfoBoxBorder;
  padding: 25px 35px;
  margin-top: 50px;

  h3 {
    font-size: 25px;
    font-family: "Ride Extrabold", sans-serif;
    margin-bottom: 10px;
  }

  p {
    margin: 0;
  }

  .button-black {
    display: inline-block;
    margin-top: 30px;
  }
}

.info-box__details {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}

.info-box__contact {
  display: flex;
  align-items: center;
  flex-direction: column;
  img {
    width: 75px;
    height: 75px;
    margin-right: 20px;
    margin-bottom: 20px;
  }
}

.info-box__faq {
  margin-top: 50px;
  text-align: center;

  p {
    font-family: "Ride", sans-serif;
    font-size: 18px;
  }
}

.info-box__desc {
  font-family: "Ride", sans-serif;
  font-size: 18px;
  color: #262626;
  .info-box--link {
    font-size: 18px;
  }

  p {
    margin: 0;
    line-height: 1;
    font-size: 18px;
  }

  p + p {
    margin-top: 15px;
  }
  h3 {
    font-size: 22px;
  }
}

.info-box__name {
  font-family: "Ride Extrabold", sans-serif;
}

@media screen and (min-width: $screen-sm) {
  .info-box {
    padding: 45px 55px;
  }

  .info-box__contact {
    flex-direction: row;
  }

  .info-box__contact {
    img {
      width: 105px;
      height: 105px;
      margin-right: 30px;
      margin-bottom: 0;
    }
  }

  .info-box__desc {
    .info-box--link {
      font-size: 25px;
    }

    .info-box__desc__paragraph {
      font-size: 25px;
    }

    h3 {
      font-size: 25px;
    }
  }
}

@media screen and (min-width: $screen-md) {
  .info-box {
    padding: 45px 55px;
  }

  .info-box__details {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 30px;
  }

  .info-box__faq {
    margin-top: 0;
    text-align: left;
  }
}
