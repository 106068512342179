.customer-journey {
  text-align: center;
  margin-top: 125px;
}

.customer-journey__title {
  margin-bottom: 80px;
  max-width: 100%;
}

.customer-journey__step {
  //   display: flex;
  //   flex-direction: column;
  margin-bottom: 80px;
}

.step__title {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 50px;
}

.step__title__wrap {
  display: flex;
  flex-direction: column;
}

.step__title__number {
  font-size: 150px;
  font-family: "Ride Extrabold", sans-serif;
  color: $colorStep;
  line-height: 1;
  text-align: right;
}

.step__title__subtitle {
  font-size: 30px;
  font-family: "Ride Extrabold", sans-serif;
  line-height: 1;
  margin-bottom: 0;
  text-transform: uppercase;
}

.step__img__wrap {
  display: flex;
  justify-content: flex-start;
}

.step__description {
  text-align: center;
}

.step__description__paragraph {
  font-size: 22px;
  font-family: "Ride Light", sans-serif;
}

.step__details {
  display: flex;
  flex-wrap: wrap;
  margin-top: 80px;
  margin-bottom: 65px;
  min-height: 190px;
}

.step__details__item {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 35px;
}

.step__details__label {
  font-size: 18px;
  font-family: "Ride Bold", sans-serif;
  line-height: 32px;
  margin: 0;
  margin-top: auto;
}

.step__button {
  text-align: center;
  font-size: 16px;
  font-family: "Ride", sans-serif;
  color: #000;
  display: inline-block;
  padding-top: 5px;
  padding-bottom: 5px;

  > .--bold {
    font-size: 16px;
  }

  &:hover {
    background-color: rgba($color: #ffd105, $alpha: 0.15);
  }
}

.--second-step {
  .step__details__item {
    width: 100%;
  }
}

.--third-step {
  .step__details__item {
    width: 100%;
  }
}

@media screen and (min-width: $screen-tablet) {
  .step__details__item {
    width: 50%;
  }

  .--second-step {
    .step__details__item {
      width: 50%;
    }
  }

  .--third-step {
    .step__details__item {
      width: 50%;
    }
  }
}

@media screen and (min-width: $screen-sm) {
  .step__title__number {
    font-size: 200px;
    line-height: 1;
  }

  .step__title__subtitle {
    font-size: 40px;
    line-height: 1.4;
  }

  .step__text {
    font-size: 18px;
    line-height: 37px;
  }

  .step__details {
    display: flex;
    flex-direction: row;
    margin-top: 80px;
    margin-bottom: 65px;
    min-height: 190px;
  }

  .step__details__item {
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0;
  }

  .--second-step {
    .step__details__item {
      width: 25%;
    }
  }

  .--third-step {
    .step__details__item {
      width: 33.333%;
    }
  }

  .axis {
    width: 100%;
    height: 100%;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      width: 1px;
      height: calc(100% - 60px);
      top: 0;
      left: 50%;
      background-color: #828282;
    }
  }
}

@media screen and (min-width: $screen-md) {
}

@media screen and (min-width: $screen-lg) {
}
