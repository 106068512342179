.accordions {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.accordions__container {
  width: 100%;
  padding: 0 20px;

  h3 {
    padding-left: 20px;
  }
}

.accordions__column {
  width: 100%;
}

.accordion__wrapper {
  width: 100%;

  &:last-child {
    margin-bottom: 50px;
  }
}

.accordion {
  width: 100%;
  text-align: left;
  border: none;
  border-bottom: 1px solid #e2e2e2;
  background-color: #fff;
  color: #262626;
  font-size: 18px;
  font-family: "Ride", sans-serif;
  padding: 20px;
  cursor: pointer;
  outline: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-image: url(../../assets/img/svg/plus.svg);
  background-repeat: no-repeat;
  background-position: 97% 50%;
  transition: background-color 0.3s ease;
}

.active,
.accordion:hover {
  font-family: "Ride Extrabold", sans-serif;
  background-color: #f7f7f7;
}

.active {
  background-image: url(../../assets/img/svg/minus.svg);
}

.accordion__content {
  // display: none;
  overflow: hidden;
  font-size: 16px;
  font-family: "Ride", sans-serif;
  padding: 0 20px;
  // padding: 0;
  max-height: 0;
  // opacity: 1;
  transition: max-height 0.5s ease-in-out;
}

.accordion__minus {
  display: none;
}

// .content-active {
//   // height: auto;
//   max-height: 1500px;
//   // opacity: 1;
//   padding: 10px 20px;
// }

@media screen and (min-width: $screen-sm) {
  .accordions {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .accordions__container {
    // width: 50%;
    padding: 0 20px;
  }

  .accordions__column {
    width: 50%;
  }

  .accordion {
    padding-right: 35px;
  }
}

@media screen and (min-width: $screen-md) {
}

@media screen and (min-width: $screen-lg) {
  .accordion {
    padding-right: 60px;
  }
}

@media screen and (min-width: $screen-xlg) {
}
