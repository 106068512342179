.cars {
  text-align: center;
  // margin-top: -200px;
  display: block;
  width: 100%;
  margin-top: -50px;
  .link {
    background-image: url(../../assets/img/png/link-arrow-right.png);
    background-repeat: no-repeat;
    background-position: 95% 70%;
    padding-right: 35px;
    transition: background-position 0.3s ease;
    &:hover {
      background-position: 100% 70%;
    }
  }
}

.cars__bcg {
  background-image: url(../../assets/img/png/cars.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 380px;
  display: inline-block;
}

@media screen and (min-width: $screen-sm) {
  .cars__bcg {
    height: 480px;
  }

  .cars {
    margin-top: -80px;
  }
}

@media screen and (min-width: $screen-md) {
  .cars__bcg {
    height: 630px;
  }
  .cars {
    margin-top: -120px;
  }
}

@media screen and (min-width: $screen-lg) {
  .cars__bcg {
    height: 820px;
  }
  .cars {
    margin-top: -220px;
  }
}

@media screen and (min-width: $screen-xlg) {
  .cars__bcg {
    // height: 1020px;
    // height: 760px;
    height: 760px;
    max-height: 760px;
  }

  .cars {
    margin-top: -150px;
  }
}

@media screen and (min-width: 1920px) {
  .cars__bcg {
    height: 1000px;
    min-height: 1000px;
  }
}
