.short-therm__box__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  .arrow-right {
    margin: 40px 0;
    align-self: center;
  }
}

.short-therm__box {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  justify-content: center;

  img {
    align-self: center;
    justify-self: center;
  }
}

.info-box--link {
  display: inline;
  font-size: 25px;
  font-family: "Ride Bold", sans-serif;
  background-image: none;
  padding-right: 0;
}

.info-box__desc__paragraph {
  font-size: 25px;
  font-family: "Ride Bold", sans-serif;
}

.lease__list {
  li {
    font-size: 18px;
    font-family: "Ride", sans-serif;
    color: #262626;
  }
}

.short-therm__box-paragraph {
  font-size: 25px;
  font-family: "Ride Bold", sans-serif;
  color: #000;
  justify-self: flex-end;
}

.short-therm__box__img {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.short-therm__box--bold {
  font-family: "Ride Extrabold", sans-serif;
  font-size: 25px;
  color: #000;
}

@media screen and (min-width: $screen-sm) {
  .short-therm__box__wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 260px;
    .arrow-right {
      margin: 0;
      margin-top: 50px;
      align-self: flex-start;
    }
  }

  .short-therm__box__img {
    width: 100%;
    height: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .short-therm__box {
    width: 33.333%;
  }

  .short-therm__subtitle {
    font-size: 25px;
    margin: 40px 0;
  }
}
