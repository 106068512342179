h1 {
  font-size: 47px;
  font-family: "Ride Extrabold", sans-serif;
  line-height: 70px;
}

h2 {
  font-size: 28px;
  font-family: "Ride Extrabold", sans-serif;
  line-height: 50px;
  margin-top: 0;
}

h3 {
  font-size: 22px;
  font-family: "Ride Extrabold", sans-serif;
  color: $colorParagraph;
  line-height: 40px;
  margin-top: 0;
}

h4 {
  font-size: 22px;
  font-family: "Ride Light", sans-serif;
  color: $colorParagraph;
  line-height: 37px;
  margin: 0;
  text-transform: uppercase;
}

h5 {
  font-size: 25px;
  font-family: "Ride Light", sans-serif;
  color: #262626;
  text-transform: uppercase;
  margin: 0;
}

p {
  font-size: 18px;
  line-height: 37px;
  color: $colorParagraph;
  font-family: "Ride", sans-serif;
}

ul {
  font-family: "Ride", sans-serif;
  padding: 0;
  list-style: none;
  list-style-image: url(../../../assets/img/png/arrow-right-small.png);
  margin-left: 15px;
  li {
    // font-size: 18px;
    line-height: 37px;
    padding-left: 15px;
  }
}

a[href^="mailto:"] {
  font-family: "Ride Bold", sans-serif;
  color: #4c9ae2;
  text-decoration: none;
}

.paragraph-bigger {
  font-size: 18px;
}

@media screen and (min-width: $screen-sm) {
  h1 {
    font-size: 56px;
    font-family: "Ride Extrabold", sans-serif;
    line-height: 60px;
  }

  h2 {
    font-size: 35px;
  }

  h3 {
    font-size: 25px;
  }

  h4 {
    font-size: 25px;
    font-family: "Ride Bold", sans-serif;
    text-transform: unset;
  }

  h5 {
    font-size: 25px;
    font-family: "Ride Light", sans-serif;
    color: #262626;
    text-transform: uppercase;
    margin: 0;
  }

  p {
    font-size: 16.5px;
  }
}

@media screen and (min-width: $screen-md) {
  h1 {
    font-size: 60px;
    font-family: "Ride Extrabold", sans-serif;
    line-height: 70px;
  }
}

@media screen and (min-width: $screen-lg) {
  h1 {
    font-size: 60px;
    font-family: "Ride Extrabold", sans-serif;
    line-height: 79px;
  }

  h3 {
    font-size: 30px;
  }
}

@media screen and (min-width: $screen-xlg) {
  h1 {
    font-size: 71px;
  }

  h3 {
    font-size: 30px;
  }
}
