.scroll-top {
  width: 45px;
  height: 45px;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  right: 0;

  &:hover {
    img {
      animation: arrowUpFloat 0.3s ease-out, arrowUp 1.5s ease-in-out infinite;
      animation-delay: 0s, 0.3s;
    }
  }
}

.scroll-top__wrap {
  width: 100%;
  height: 45px;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10000;
}

@media screen and (min-width: $screen-sm) {
  .scroll-top__wrap {
    width: 970px;
  }
}

@media screen and (min-width: $screen-md) {
  .scroll-top__wrap {
    width: 1170px;
  }
}

@media screen and (min-width: $screen-lg) {
  .scroll-top__wrap {
    width: 1250px;
  }
}

@media screen and (min-width: $screen-xlg) {
  .scroll-top__wrap {
    width: 1370px;
  }
}

@keyframes arrowUp {
  0% {
    transform: translateY(-6px);
  }

  50% {
    transform: translateY(-2px);
  }

  100% {
    transform: translateY(-6px);
  }
}

@keyframes arrowUpFloat {
  100% {
    transform: translateY(-6px);
  }
}
