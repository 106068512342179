header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 96px;
  background-color: rgba($color: #fff, $alpha: 0.9);
  z-index: 1000;
  transition: height 0.3s ease, background-color 0.3s ease;
}

.menu-scrolled {
  height: 86px;
  border-bottom: 2px solid #e7e7e7;
  background-color: rgba($color: $colorNavbar, $alpha: 0.98);

  .navbar {
    height: 96px;
  }

  .navbar__submenu {
    top: 86px;
  }
}

.navbar {
  height: 96px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar__logo {
  display: flex;
  align-items: center;
}

.navbar__menu {
  height: 100%;
  font-size: 16.5px;
  text-transform: uppercase;
  // display: none;
  visibility: hidden;
  opacity: 0;
  display: none;
}

.menu {
  display: flex;
  margin: 0;
  height: 100%;
  z-index: 100;
  li {
    padding: 0;
  }
}

.navbar__submenu {
  background-color: #fff;
  color: $colorMenu;
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 96px;
  left: 0;
  margin: 0;
  transition: top 0.3s ease;
  // display: none;
  // z-index: 100;
  // visibility: hidden;
  // transition: all 1s ease;
  // z-index: -1;
  height: 0;
  opacity: 0;
  transition: all 0.5s ease;
  li {
    border-bottom: 1px solid #e3e3e3;
    height: 0;
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s ease;
    a {
      text-decoration: none;
      color: #7b7b7b;
      font-size: 16px;
      font-family: "Ride Bold", sans-serif;
      white-space: nowrap;
      padding: 10px 30px;
      display: inline-block;
      text-transform: initial;
      width: 100%;
      // &:hover {
      //   color: $colorMenuHover;
      // }
    }
  }
}

.navbar__link {
  color: $colorMenu;
  text-decoration: none;
  font-family: "Ride Extrabold", sans-serif;
  display: inline-block;
  position: relative;

  // &:hover {
  //   color: $colorMenuHover;

  //   &::after,
  //   &::before {
  //     width: 100%;
  //     left: 0;
  //   }
  // }
  // &::after,
  // &::before {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   width: 0;
  //   right: 0;
  //   height: 5px;
  // }

  // &::before {
  //   transition: width 0.4s cubic-bezier(0.51, 0.18, 0, 0.88) 0.1s;
  //   background: $colorBorder;
  // }

  // &::after {
  //   transition: width 0.2s cubic-bezier(0.29, 0.18, 0.26, 0.83);
  //   background: $colorBorder;
  // }
}

.navbar__link.navbar__link--active {
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: $colorBorder;
  }
}

.responsive-menu {
  position: relative;
  display: block;
  height: 60px;
  width: 60px;
  z-index: 1;
  padding: 0;
  margin: 0;
  margin-right: 10px;
  // margin-right: 20px;
  cursor: pointer;
  outline: none;
  pointer-events: auto;
  border-radius: 0;
  border: none;
  background: none;
  transition: width 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  .line {
    position: absolute;
    right: 18px;
    left: 18px;
    height: 3px;
    width: 30px;
    display: block;
    transform-origin: 0 50%;
    transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    background-color: #000;
    z-index: 1;
  }

  .line-1 {
    top: 24px;
    transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transform: scaleX(0.83);
  }

  .line-2 {
    top: 32px;
    transition: width 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }

  .line-3 {
    top: 40px;
    transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transform: scaleX(0.66);
  }

  &:hover {
    .line {
      background: $colorBorder;
      transform: scaleX(1);
    }
  }
}

.opened-menu {
  display: block;
  visibility: visible;
  opacity: 1;
  position: absolute;
  top: 96px;
  right: 0;
  height: 100vh;
  width: 70vw;
  background-color: #fff;
  transition: opacity 0.3s ease;
  .menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 8%;
    padding-top: 30px;
  }

  li {
    width: 100%;
  }

  .menu {
    .navbar__link {
      width: 100%;
      margin: 5px 0;
      font-size: 15px;
      // &:hover {
      //   &::after,
      //   &::before {
      //     width: 100px;
      //     left: 0;
      //   }
      // }
      // &::after,
      // &::before {
      //   content: "";
      //   position: absolute;
      //   top: 0;
      //   width: 0;
      //   left: 0;
      //   height: 5px;
      // }
    }
  }
  .navbar__submenu {
    position: static;
    display: block;
    height: auto;
    opacity: 1;

    li {
      border-bottom: 0;
      height: auto;
      opacity: 1;
      visibility: visible;
    }
    a {
      padding: 5px 10px;
      width: 100%;
      font-size: 16px;
      font-family: "Ride", sans-serif;
      line-height: 1;
    }
  }
}

@media screen and (min-width: $screen-sm) {
  .--submenu {
    position: relative;

    &:hover .navbar__submenu {
      display: flex;
      // visibility: visible;
      // top: 96px;
      height: auto;
      opacity: 1;

      li {
        height: 57px;
        visibility: visible;
        opacity: 1;
      }
    }
  }

  .navbar__submenu {
    li {
      a {
        &:hover {
          color: $colorMenuHover;
        }
      }
    }
  }

  .opened-menu {
    width: 50vw;
  }

  .opened-menu {
    .menu {
      padding-left: 10%;
    }

    .menu {
      .navbar__link {
        width: 100%;
        margin: 5px 0;
        font-size: 16px;
        &:hover {
          &::after,
          &::before {
            width: 100px;
            left: 0;
          }
        }
        &::after,
        &::before {
          content: "";
          position: absolute;
          top: 0;
          width: 0;
          left: 0;
          height: 5px;
        }
      }
    }

    .navbar__submenu {
      position: static;
      display: block;
      li {
        border-bottom: 0;
      }
      a {
        padding: 5px 30px;
        width: 100%;
        font-size: 16px;
      }
    }
  }

  .navbar__link {
    color: $colorMenu;
    text-decoration: none;
    font-family: "Ride Extrabold", sans-serif;
    display: inline-block;
    position: relative;

    &:hover {
      color: $colorMenuHover;

      &::after,
      &::before {
        width: 100%;
        left: 0;
      }
    }
    &::after,
    &::before {
      content: "";
      position: absolute;
      top: 0;
      width: 0;
      right: 0;
      height: 5px;
    }

    &::before {
      transition: width 0.4s cubic-bezier(0.51, 0.18, 0, 0.88) 0.1s;
      background: $colorBorder;
    }

    &::after {
      transition: width 0.2s cubic-bezier(0.29, 0.18, 0.26, 0.83);
      background: $colorBorder;
    }
  }
}

@media screen and (min-width: $screen-md) {
}

@media screen and (min-width: $screen-lg) {
  .navbar__menu {
    font-size: 16px;
  }
  // .navbar__link {
  //   margin: 0 20px;
  // }

  .navbar__menu {
    display: flex;
    visibility: visible;
    opacity: 1;
  }
  .navbar__link {
    display: flex;
    // margin: 0 8px;
    margin: 0;
    margin-left: 45px;
    height: 100%;
    align-items: center;
  }

  .responsive-menu {
    display: none;
  }
}
