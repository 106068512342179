.partnership-block {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  img {
    margin-bottom: 50px;
    margin-right: 50px;
    height: 205px;
  }
}

.partnership-block__description {
  p {
    margin-top: 0;
    font-size: 18px;
    line-height: 37px;
  }

  p + p {
    margin-top: 50px;
  }
}

.partnership__gallery {
  text-align: center;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.gallery__item {
  flex: 1 1 auto;
  box-flex: 1;

  img {
    max-width: 100%;
    height: auto;
  }
}

@media screen and (min-width: $screen-sm) {
  .partnership-block {
    flex-direction: row;
    img {
      margin-bottom: 0;
    }
  }
}
