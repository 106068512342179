@import "../css/sanitize.css";
@import "base/fonts";
@import "base/colors";
@import "base/grid";
@import "base/layout";
@import "base/typography";

@import "menu";
@import "footer";
@import "hero";
@import "block-img";
@import "customer-journey";
@import "info-box";
@import "lease";
@import "shorttherm";
@import "medium-therm";
@import "hertz-for-business";
@import "about";
@import "partnership";
@import "departments";
@import "news-single";
@import "homepage";
@import "homepage-cars";
@import "homepage-banners";
@import "newsletter";
@import "our-fleet";
@import "news";
@import "faq";
@import "scroll-top";
