.banners {
  margin-top: 100px;
}

.banners__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px;
}

.banner__wrap {
  overflow: hidden;
  position: relative;
  margin-top: 80px;
  display: inline-block;
  width: 100%;
  &:hover {
    .banner {
      transform: scale(1.05);

      &::before {
        opacity: 1;
      }
    }
  }
}

.banner {
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  // padding: 40px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  transition: transform 0.3s ease;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.1);
    opacity: 0;
    transition: opacity 0.3s ease;
  }
}

.link--banner {
  margin-left: 37px;
}

.banners-small {
  .banner__wrap {
    &:nth-of-type(1) {
      .banner {
        background-image: url(../../assets/img/jpg/banner-01.png);
      }
    }
    &:nth-of-type(2) {
      .banner {
        background-image: url(../../assets/img/jpg/banner-02.png);
      }
    }
  }
}

.banners-big {
  .banner {
    &:nth-of-type(1) {
      background-image: url(../../assets/img/jpg/banner-03.jpg);
    }
  }
}

.banner--big {
  width: 100%;
  height: 500px;
}

.banner__content {
  padding: 40px;
  position: absolute;
  left: 0;
  bottom: 0;
  transition: all 0.3s ease;
}

.banner-title__link {
  text-decoration: none;
}

.banner__title {
  font-size: 28px;
  font-family: "Ride Extrabold", sans-serif;
  color: #fff;
  margin: 0;
  // position: relative;
  display: inline-flex;
  line-height: 1.2;

  &::before {
    content: "";
    // position: absolute;
    top: 0;
    left: 0;
    min-width: 22px;
    height: 3px;
    background-color: #fff;
    margin-right: 15px;
    margin-top: 20px;
  }
}

.banner__text {
  font-size: 16px;
  font-family: "Ride Semi Bold", sans-serif;
  color: #fff;
  line-height: 1.4;
  margin-left: 37px;
}

@media screen and (min-width: $screen-sm) {
  .banners__wrapper {
    flex-direction: row;
  }

  .banner__wrap {
    margin-top: 50px;
    width: 435px;
    height: 250px;
  }

  .banner {
    width: 100%;
    height: 100%;
    // margin-top: 50px;
  }

  .banner__content {
    padding: 20px;
  }

  .banner__title {
    font-size: 24px;
  }

  .banner__wrap + .banner__wrap {
    margin-top: 20px;
  }

  .banner__wrap--big {
    width: 460px;
    height: 520px;
  }

  .banner--big {
    width: 460px;
    height: 520px;
  }
}

@media screen and (min-width: $screen-md) {
  .banner__wrap {
    margin-top: 45px;
    width: 515px;
    height: 290px;
  }
  .banner {
    width: 100%;
    height: 100%;
  }

  .banner__wrap + .banner__wrap {
    margin-top: 45px;
  }

  .banner__wrap--big {
    width: 560px;
    height: 625px;
  }

  .banner--big {
    width: 100%;
    height: 100%;
  }

  .banner__content {
    padding: 30px;
  }

  .banner__title {
    font-size: 25px;
  }
}

@media screen and (min-width: $screen-lg) {
  .banner__wrap {
    margin-top: 80px;
    width: 600px;
    height: 325px;
  }

  .banner__wrap + .banner__wrap {
    margin-top: 80px;
  }

  .banner {
    width: 100%;
    height: 100%;
  }

  .banner__wrap--big {
    width: 650px;
    height: 730px;
  }

  .banner--big {
    width: 100%;
    height: 100%;
  }

  .banners-little {
    .related {
      padding: 0;
    }
  }

  .banner__content {
    padding: 40px;
  }

  .banner__title {
    font-size: 28px;
  }
}

@media screen and (min-width: $screen-xlg) {
  .banner__wrap {
    width: 615px;
    height: 335px;
  }

  .banner {
    width: 100%;
    height: 100%;
  }

  .banner__wrap--big {
    width: 670px;
    height: 750px;
  }

  .banner--big {
    width: 100%;
    height: 100%;
  }

  .banners-little {
    .related {
      padding: 0;
    }
  }
}
