.our-fleet {
  margin-top: 166px;
}

table {
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table tr {
  padding: 0.35em;
}

table th,
table td {
  padding: 0.625em;
  text-align: center;
}

table th {
  text-transform: uppercase;
  &:first-of-type {
    width: 150px;
  }
  &:nth-of-type(3) {
    width: 150px;
  }
  &:last-of-type {
    width: 450px;
  }
}

td {
  color: #262626;
}

tbody {
  tr {
    border-bottom: 1px solid #e2e2e2;
    td {
      font-size: 17px;
      font-family: "Ride Semi Bold", sans-serif;
      &:last-of-type {
        text-align: left;
        font-size: 15px;
        font-family: "Ride", sans-serif;
        line-height: 24px;
      }
    }
  }
}

thead {
  background-color: #f4f4f4;
  th {
    padding: 25px 0;
    color: #7b7b7b;
    font-size: 13px;
    font-family: "Ride Bold", sans-serif;
    font-weight: normal;
  }
}

.car-model {
  font-family: "Ride Extrabold", sans-serif;
  font-size: 20px;
  color: #000;
  display: block;
  margin-bottom: 10px;
}

.car-image {
  width: 155px;
  height: 100px;
}

@media screen and (max-width: 1169px) {
  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }

  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: 0.625em;
  }

  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    text-align: right;
  }

  table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  tbody {
    tr {
      td {
        &:last-of-type {
          font-size: 15px;
          font-family: "Ride", sans-serif;
          &::before {
            width: 100%;
            font-size: 17px;
            font-family: "Ride Semi Bold", sans-serif;
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  table td:last-child {
    border-bottom: 0;
  }
}

@media screen and (min-width: $screen-md) {
  table th:last-of-type {
    width: 250px;
  }
}

@media screen and (min-width: $screen-lg) {
  table th:last-of-type {
    width: 450px;
  }
}
