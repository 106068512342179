$screen-tablet: 768px !default;

$screen-mobile: 969px !default;

$screen-sm: 970px !default;

$screen-md: 1170px !default;

$screen-lg: 1452px !default;

$screen-xlg: 1650px !default;
