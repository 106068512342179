.block-img {
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 60px;
}

.block-img__image {
  width: 60%;
  height: 100%;
  margin: auto;
}

.block-img__description {
  width: 100%;
  font-family: "Ride", sans-serif;

  p {
    color: #262626;
    font-size: 18px;
    margin-top: 0;
  }

  p + p {
    margin-top: 50px;
  }
}

.block-img__title {
  display: inline-flex;
  align-items: center;
  font-size: 30px;
  font-family: "Ride Extrabold", sans-serif;
  line-height: 1;
  margin-bottom: 15px;

  &::before {
    content: "";
    left: 0;
    top: 0;
    height: 4px;
    width: 20px;
    margin-right: 15px;
    background: $colorDecor;
  }
}

.block-img--reverse {
  flex-direction: column-reverse;
  text-align: left;

  .block-img__image {
    padding-right: 0;
    padding-left: 30px;
  }

  .block-img__description {
    padding-left: 0;
    padding-right: 30px;
  }

  .block-img__title {
    &::before {
      display: none;
    }

    &::after {
      content: "";
      left: 0;
      top: 0;
      height: 4px;
      width: 20px;
      margin-left: 15px;
      background: $colorDecor;
    }
  }
}

@media screen and (min-width: $screen-sm) {
  .block-img {
    display: flex;
    flex-direction: row;
    margin-bottom: 60px;
  }

  .block-img__title {
    font-size: 24px;
  }

  .block-img__image {
    width: 50%;
    height: 100%;
    padding-right: 15px;
  }

  .block-img__description {
    width: 50%;
    padding-left: 15px;
  }

  .block-img--reverse {
    flex-direction: row-reverse;
    text-align: right;
    .block-img__image {
      padding-right: 0;
      padding-left: 15px;
    }
    .block-img__description {
      padding-left: 0;
      padding-right: 15px;
    }
  }
}

@media screen and (min-width: $screen-md) {
  .block-img__title {
    font-size: 30px;
  }

  .block-img__image {
    padding-right: 30px;
  }

  .block-img__description {
    padding-left: 30px;
  }

  .block-img--reverse {
    .block-img__image {
      padding-left: 30px;
    }

    .block-img__description {
      padding-right: 30px;
    }
  }
}

@media screen and (min-width: $screen-lg) {
}
