.newsletter {
  text-align: right;
  margin: 100px 0;

  > .container {
    padding: 0 30px;
  }
}

.newsletter__btn {
  width: 80%;
  border: none;
  border-bottom: 3px solid #7b7b7b;
  font-size: 20px;
  font-family: "Ride Extrabold", sans-serif;
  color: #7b7b7b;
  padding-left: 0;
  text-decoration: none;
  display: inline-block;
  text-align: left;
  padding-bottom: 10px;
  transition: opacity 0.3s ease;
}

.newsletter__form__wrap {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: all 1s ease;
}

.newsletter__form {
  text-align: left;
}

.newsletter__form--active {
  max-height: 2000px;
  // max-height: 1500px;
  opacity: 1;
  margin-bottom: 60px;
}

.newsletter__btn--hide {
  opacity: 0;
}

.newsletter__title {
  text-align: center;
  margin-bottom: 80px;
}

.newsletter__agreements {
  display: flex;
  flex-direction: column;
  margin-top: 60px;
}

.newsletter__agreement {
  font-size: 16px;
  color: #262626;
  font-family: "Ride", sans-serif;
  line-height: 30px;
  width: 100%;
  padding: 0 15px;
  padding-left: 45px;
  position: relative;
  margin-top: 30px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 26px;
  width: 26px;
  background-color: #fff;
  border: 1px solid #bcbcbc;
  margin-top: 3px;
}

.newsletter__agreement:hover input ~ .checkmark {
  background-color: #e9e9e9;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.newsletter__agreement input:checked ~ .checkmark:after {
  display: block;
}

.newsletter__agreement .checkmark:after {
  left: 8px;
  top: 3px;
  width: 8px;
  height: 13px;
  border: solid #000;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.newsletter__submit {
  display: inherit;
  width: auto;
  margin: 60px auto 0 auto;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 40px white inset !important;
}

.input {
  position: relative;
  z-index: 1;
  display: inline-block;
  margin: 1em;
  max-width: 350px;
  width: calc(100% - 2em);
  vertical-align: top;
  font-family: "Ride Extrabold", sans-serif;
}

.input__field {
  position: relative;
  display: block;
  float: right;
  padding: 0.8em;
  width: 60%;
  border: none;
  border-radius: 0;
  font-family: "Ride Extrabold", sans-serif;
  -webkit-appearance: none; /* for box shadows to show on iOS */
}

.input__field:focus {
  outline: none;
}

.input__label {
  display: inline-block;
  float: right;
  padding: 0 1em;
  // width: 40%;
  // font-size: 70%;
  font-family: "Ride Extrabold", sans-serif;
  color: #7b7b7b;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.input__label-content {
  position: relative;
  display: block;
  // padding: 20px 0;
  // padding-bottom: 60px;
  // height: 100%;
  padding: 1.6em 0;
  width: 100%;
  font-family: "Ride Extrabold", sans-serif;
}

.input--hoshi {
  overflow: hidden;
}

.input__field--hoshi {
  margin-top: 0;
  padding: 0.85em 0.15em;
  padding-bottom: 0;
  padding-top: 30px;
  // padding-top: 10px;
  width: 100%;
  background: transparent;
  color: #000;
  font-size: 22px;
  height: 100%;
}

.input__label--hoshi {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 0.25em;
  width: 100%;
  height: 100%;
  text-align: left;
  pointer-events: none;
}

.input__label-content--hoshi {
  position: absolute;
}

.input__label--hoshi::before,
.input__label--hoshi::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  // height: calc(100% - 10px);
  height: 100%;
  border-bottom: 2px solid #7b7b7b;
}

.input__label--hoshi::after {
  margin-top: 2px;
  border-bottom: 4px solid #ffd105;
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
}

.input__label--hoshi-color::after {
  border-color: #ffd105;
}

.input__field--hoshi:focus + .input__label--hoshi::after,
.input--filled .input__label--hoshi::after {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.input__field--hoshi:focus + .input__label--hoshi .input__label-content--hoshi,
.input--filled .input__label-content--hoshi {
  -webkit-animation: anim-1 0.3s forwards;
  animation: anim-1 0.3s forwards;
}

// @-webkit-keyframes anim-1 {
//   50% {
//     opacity: 0;
//     -webkit-transform: translate3d(1em, 0, 0);
//     transform: translate3d(1em, 0, 0);
//   }
//   51% {
//     opacity: 0;
//     -webkit-transform: translate3d(-1em, -40%, 0);
//     transform: translate3d(-1em, -40%, 0);
//   }
//   100% {
//     opacity: 1;
//     -webkit-transform: translate3d(0, -40%, 0);
//     transform: translate3d(0, -40%, 0);
//   }
// }

@keyframes anim-1 {
  50% {
    opacity: 0;
    -webkit-transform: translate3d(1em, 0, 0);
    transform: translate3d(1em, 0, 0);
  }
  51% {
    opacity: 0;
    -webkit-transform: translate3d(-1em, -40%, 0);
    transform: translate3d(-1em, -40%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, -40%, 0);
    transform: translate3d(0, -40%, 0);
  }
}

@media screen and (min-width: $screen-sm) {
  .newsletter__btn {
    width: 50%;
  }

  .newsletter__agreements {
    display: flex;
    flex-direction: row;
    margin-top: 60px;
  }
  .newsletter__agreement {
    width: 50%;
    margin-top: 0;
  }
}
