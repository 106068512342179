* {
  box-sizing: border-box;
  font-family: "Lato Regular", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: normal;
}

.container {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 20px;
}

.content__title {
  margin-bottom: 50px;
}

.cover--active {
  width: 100%;
  height: 100%;
  &::after {
    content: "";
    background-color: rgba($color: #000000, $alpha: 0.15);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    pointer-events: none;
    width: 100%;
    height: 100%;
  }
}

.button {
  background: $colorBtnBcg;
  border: 1px solid $colorBtnBorder;
  text-transform: uppercase;
  padding: 25px 30px 25px 15px;
  color: $colorText;
  text-decoration: none;
}

.button-icon {
  margin-right: 25px;
}

.button-black {
  text-decoration: none;
  text-transform: uppercase;
  color: $colorBlackBtnText;
  background-color: $colorBlackBtn;
  padding: 15px 40px;
  font-family: "Ride Bold", sans-serif;
  font-size: 16px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #ffcc00;
  }
}

.--btn-share {
  font-size: 16px;
  text-transform: unset;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.share-items__wrap {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.share-item {
  color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  // float: left;
  background-color: #fff;
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 50%;
  &:nth-child(1) {
    transform: translateX(200px) rotate(180deg);
    transition: 100ms cubic-bezier(0.32, -0.22, 0.9, 0.93) all;
  }
  &:nth-child(2) {
    transform: translateX(400px) rotate(200deg);
    transition: 250ms cubic-bezier(0.32, -0.22, 0.9, 0.93) all;
  }
  &:nth-child(3) {
    transform: translateX(580px) rotate(220deg);
    transition: 400ms cubic-bezier(0.32, -0.22, 0.9, 0.93) all;
  }
}

.--btn-share-open {
  background-color: #ffcc00;
  color: #ffcc00;
  .share-item {
    &:nth-child(1) {
      transform: translateX(0) rotate(0);
    }
    &:nth-child(2) {
      transform: translateX(0) rotate(0);
    }
    &:nth-child(3) {
      transform: translateX(0) rotate(0);
    }
  }
}

.link {
  font-size: 16px;
  color: $colorLink;
  text-decoration: none;
  display: block;
  font-family: "Ride Bold", sans-serif;
  background-image: url(../../../assets/img/png/link-arrow.png);
  background-repeat: no-repeat;
  background-position: 95% 70%;
  display: inline-block;
  padding-right: 35px;
  transition: background-position 0.3s ease-out;
  &:hover {
    background-position: 100% 70%;
  }
}

.link--gray {
  color: #7b7b7b;
  font-size: 20px;
}

.link--banner {
  color: #ffd105;
  font-size: 16px;
  font-family: "Ride Extrabold", sans-serif;
  padding-right: 35px;
  background-image: url(../../../assets/img/png/link-arrow-yellow.png);
  background-position: 90% 70%;
  align-self: flex-start;
  transition: background-position 0.3s ease-out;
  &:hover {
    background-position: 100% 70%;
  }
}

.link--website {
  color: #262626;
  font-family: "Ride Bold", sans-serif;
  font-size: 18px;
  text-decoration: none;
}

.link--website-blue {
  text-decoration: none;
  font-family: "Ride Bold", sans-serif;
  color: $colorLink;
}

input {
  border: 1px solid $colorInputBorder;
  width: 100%;
  padding: 15px;
  font-size: 16px;
  color: $colorPlaceholder;

  &::placeholder {
    font-size: 16px;
    color: $colorPlaceholder;
  }
}

.--bold {
  font-family: "Ride Bold", sans-serif;
  font-weight: bold;
}

@media screen and (min-width: $screen-sm) {
  .container {
    width: $screen-sm;
    padding: 0;
  }
}

@media screen and (min-width: $screen-md) {
  .container {
    width: $screen-md;
  }
}

@media screen and (min-width: $screen-lg) {
  .container {
    width: $screen-lg;
  }
}

@media screen and (min-width: $screen-xlg) {
  .container {
    width: $screen-xlg;
  }
}
