@font-face {
  font-family: "Ride Bold";
  src: url("../../../assets/fonts/Ride-Bold.eot");
  src: url("../../../assets/fonts/Ride-Bold.eot.eot?#iefix")
      format("embedded-opentype"),
    url("../../../assets/fonts/Ride-Bold.woff") format("woff"),
    url("../../../assets/fonts/Ride-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Ride Bold Italic";
  src: url("../../../assets/fonts/Ride-BoldItalic.eot");
  src: url("../../../assets/fonts/Ride-BoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../../../assets/fonts/Ride-BoldItalic.woff") format("woff"),
    url("../../../assets/fonts/Ride-BoldItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Ride Italic";
  src: url("../../../assets/fonts/Ride-Italic.eot");
  src: url("../../../assets/fonts/Ride-Italic.eot.eot?#iefix")
      format("embedded-opentype"),
    url("../../../assets/fonts/Ride-Italic.woff") format("woff"),
    url("../../../assets/fonts/Ride-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Ride Semi Bold";
  src: url("../../../assets/fonts/Ride-SemiBold.eot");
  src: url("../../../assets/fonts/Ride-SemiBold.eot.eot?#iefix")
      format("embedded-opentype"),
    url("../../../assets/fonts/Ride-SemiBold.woff") format("woff"),
    url("../../../assets/fonts/Ride-SemiBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Ride Semi Bold Italic";
  src: url("../../../assets/fonts/Ride-SemiBold-Italic.eot");
  src: url("../../../assets/fonts/Ride-SemiBold-Italic.eot.eot?#iefix")
      format("embedded-opentype"),
    url("../../../assets/fonts/Ride-SemiBold-Italic.woff") format("woff"),
    url("../../../assets/fonts/Ride-SemiBold-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Ride";
  src: url("../../../assets/fonts/Ride.eot");
  src: url("../../../assets/fonts/Ride.eot.eot?#iefix")
      format("embedded-opentype"),
    url("../../../assets/fonts/Ride.woff") format("woff"),
    url("../../../assets/fonts/Ride.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Ride Condensed Italic";
  src: url("../../../assets/fonts/RideCondensed-Italic.eot");
  src: url("../../../assets/fonts/RideCondensed-Italic.eot.eot?#iefix")
      format("embedded-opentype"),
    url("../../../assets/fonts/RideCondensed-Italic.woff") format("woff"),
    url("../../../assets/fonts/RideCondensed-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Ride Condensed";
  src: url("../../../assets/fonts/RideCondensed.eot");
  src: url("../../../assets/fonts/RideCondensed.eot.eot?#iefix")
      format("embedded-opentype"),
    url("../../../assets/fonts/RideCondensed.woff") format("woff"),
    url("../../../assets/fonts/RideCondensed.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Ride Extrabold Italic";
  src: url("../../../assets/fonts/RideExtrabold-Italic.eot");
  src: url("../../../assets/fonts/RideExtrabold-Italic.eot.eot?#iefix")
      format("embedded-opentype"),
    url("../../../assets/fonts/RideExtrabold-Italic.woff") format("woff"),
    url("../../../assets/fonts/RideExtrabold-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Ride Extrabold";
  src: url("../../../assets/fonts/RideExtrabold.eot");
  src: url("../../../assets/fonts/RideExtrabold.eot.eot?#iefix")
      format("embedded-opentype"),
    url("../../../assets/fonts/RideExtrabold.woff") format("woff"),
    url("../../../assets/fonts/RideExtrabold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Ride Light Italic";
  src: url("../../../assets/fonts/RideLight-Italic.eot");
  src: url("../../../assets/fonts/RideLight-Italic.eot.eot?#iefix")
      format("embedded-opentype"),
    url("../../../assets/fonts/RideLight-Italic.woff") format("woff"),
    url("../../../assets/fonts/RideLight-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Ride Light";
  src: url("../../../assets/fonts/RideLight.eot");
  src: url("../../../assets/fonts/RideLight.eot.eot?#iefix")
      format("embedded-opentype"),
    url("../../../assets/fonts/RideLight.woff") format("woff"),
    url("../../../assets/fonts/RideLight.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
