.departments__search {
  border-bottom: 1px solid $colorInputBorder;
  padding-bottom: 70px;
}

.departments__search__wrapper {
  position: relative;
  width: 100%;
  margin-bottom: 45px;
}

.departments__search__label {
  font-size: 18px;
  font-family: "Ride Bold", sans-serif;
  margin-bottom: 20px;
  display: block;
  line-height: 30px;
}

.departments__search__input {
  font-family: "Ride", sans-serif;
  font-size: 16px;
  color: #7b7b7b;
  padding: 0 0 0 15px;
  height: 50px;

  &::placeholder {
    font-family: "Ride", sans-serif;
    font-size: 16px;
    color: #7b7b7b;
  }
}

.departments__search__icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  padding: 0 15px;
  display: flex;
  align-items: center;
  svg {
    path {
      transition: fill 0.3s ease;
    }
  }
  &:hover {
    svg {
      path {
        fill: #ffd105;
      }
    }
  }
}

.departments__localization__wrap {
  width: 100%;
}

.departments__localization {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.departments__localization__info {
  align-self: flex-start;
  border-bottom: 1px solid $colorInputBorder;
  padding-bottom: 20px;
  margin-top: 20px;

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    border: none;
  }
}

.info__paragraph {
  margin: 0;
  margin-bottom: 0;
  line-height: 25px;
  font-family: "Ride", sans-serif;
  color: #262626;
  font-size: 16px;

  &:first-of-type {
    font-family: "Ride Extrabold", sans-serif;
  }
}

.link--more-info {
  margin-top: 20px;
}

//tab

.departments__list {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid $colorInputBorder;
  padding-bottom: 70px;

  .link--search-all {
    margin-top: 35px;
    background-position: 95% calc(100% - 3px);
    padding-right: 35px;
    transition: background-position 0.3s ease-out;
    &:hover {
      background-position: 100% calc(100% - 3px);
    }
  }
}

.departments__list__column {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.departments__list__link {
  text-decoration: none;
  font-size: 18px;
  color: $colorDepartments;
  margin: 10px 0;
  height: 26px;
  line-height: 26px;
  font-family: "Ride Regular", sans-serif;
}

.department--active {
  font-weight: bold;
  display: flex;
  align-items: center;
  font-family: "Ride Extrabold", sans-serif;

  &::after {
    content: url(../../assets/img/png/icon-check-yellow.png);
    margin-left: 25px;
  }
}

.link--search-all {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.info__communicat {
  margin-top: 30px;
  display: block;
  font-size: 15px;
  font-family: "Ride Italic", sans-serif;
  line-height: 20px;
  color: $colorCommunicat;
}

// tabs

.tabs {
  position: relative;
  margin-top: -400px;
}

.tab-content {
  display: none;
}

.tab-content--active {
  display: block;
}

.departments__tabs {
  position: absolute;
  left: 0;
  top: -50px;
  display: flex;
}

.departments__tab {
  padding: 0 20px;
  height: 50px;
  background-color: $colorInactiveTabBcg;
  color: $colorInactiveTab;
  font-size: 18px;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-family: "Ride Extrabold", sans-serif;
  transition: color 0.3s ease;
  &:hover {
    color: #000;
  }
}

.departments__tab.--active-tab {
  background-color: $colorActiveTabBcg;
  color: $colorActiveTab;
  border-top: 5px solid $colorBorder;
  font-weight: bold;
}

.map {
  width: 100%;
  margin-top: 40px;
  height: 400px;
}

@media screen and (min-width: $screen-sm) {
  .departments__localization {
    flex-direction: row;
  }

  .departments__localization__wrap {
    padding-right: 90px;
    width: 40%;
  }

  .map {
    width: 60%;
    margin-top: 0;
    height: 320px;
  }

  .departments__search__wrapper {
    // width: 780px;
    width: 60%;
  }

  .departments__list__column {
    width: 20%;
  }

  .departments__list {
    .link--search-all {
      margin-top: 0;
      width: 20%;
      text-align: right;
    }
  }

  .departments-list {
    h3 {
      font-size: 35px;
      color: #000;
    }
  }
}
